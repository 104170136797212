/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeConditionRequest
 */
export interface AttributeConditionRequest {
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionRequest
     */
    rowAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionRequest
     */
    attributeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionRequest
     */
    operatorType?: AttributeConditionRequestOperatorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AttributeConditionRequest
     */
    dataRegistryImportType?: AttributeConditionRequestDataRegistryImportTypeEnum;
}


/**
 * @export
 */
export const AttributeConditionRequestOperatorTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    LessThan: 'LESS_THAN',
    LessThanOrEquals: 'LESS_THAN_OR_EQUALS',
    GreaterThan: 'GREATER_THAN',
    GreaterThanOrEquals: 'GREATER_THAN_OR_EQUALS',
    Like: 'LIKE',
    NotNull: 'NOT_NULL'
} as const;
export type AttributeConditionRequestOperatorTypeEnum = typeof AttributeConditionRequestOperatorTypeEnum[keyof typeof AttributeConditionRequestOperatorTypeEnum];

/**
 * @export
 */
export const AttributeConditionRequestDataRegistryImportTypeEnum = {
    Create: 'CREATE',
    Update: 'UPDATE',
    Merge: 'MERGE'
} as const;
export type AttributeConditionRequestDataRegistryImportTypeEnum = typeof AttributeConditionRequestDataRegistryImportTypeEnum[keyof typeof AttributeConditionRequestDataRegistryImportTypeEnum];


/**
 * Check if a given object implements the AttributeConditionRequest interface.
 */
export function instanceOfAttributeConditionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttributeConditionRequestFromJSON(json: any): AttributeConditionRequest {
    return AttributeConditionRequestFromJSONTyped(json, false);
}

export function AttributeConditionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeConditionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rowAttributeCode': !exists(json, 'rowAttributeCode') ? undefined : json['rowAttributeCode'],
        'attributeValue': !exists(json, 'attributeValue') ? undefined : json['attributeValue'],
        'operatorType': !exists(json, 'operatorType') ? undefined : json['operatorType'],
        'dataRegistryImportType': !exists(json, 'dataRegistryImportType') ? undefined : json['dataRegistryImportType'],
    };
}

export function AttributeConditionRequestToJSON(value?: AttributeConditionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rowAttributeCode': value.rowAttributeCode,
        'attributeValue': value.attributeValue,
        'operatorType': value.operatorType,
        'dataRegistryImportType': value.dataRegistryImportType,
    };
}

