import React from 'react';
import Header from '../components/layout/Header';
import useCodelistApi from '../hooks/use-codelist-api';
import { InfoApi as CodelistInfoApi } from '../openapi/codelist';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useCatalogApi from '../hooks/use-catalog-api';
import { InfoApi as CatalogInfoApi } from '../openapi/catalog';
import { InfoApi as DataImportInfoApi } from '../openapi/data-import';
import { InfoControllerApi as OverviewInfoApi } from '../openapi/overview';
import { InfoApi as DataImportMetadataInfoApi } from '../openapi/data-import-metadata';
import { InfoApi as NotificatiorInfoApi } from '../openapi/notificator';
import { InfoApi as UserProfileInfoApi } from '../openapi/user-profile';
import { InfoApi as CatalogImportInfoApi } from '../openapi/catalog-import';
import { InfoApi as MethodicImportInfoApi } from '../openapi/methodic-import';
import useDataImportMetadataApi from '../hooks/use-data-import-metadata-api';
import useDataImportApi from '../hooks/use-data-import-api';
import useOverviewApi from '../hooks/use-overview-api';
import useNotificatorApi from '../hooks/use-notificator-api';
import useUserProfileApi from '../hooks/use-user-profile-api';
import useCatalogImportApi from '../hooks/use-catalog-import-api';
import useMethodicImportApi from '../hooks/use-methodic-import-api';
import useSupportingRegistersApi from '../hooks/use-herki-api';
import { HealthCheckControllerApi as SupportingRegistersApi } from '../openapi/herki';

const ModulItem: React.FC<{ title: string; data: UseQueryResult<string, Error> }> = ({ title, data }) => {
    return (
        <li className='flex'>
            <div className='min-w-64'>{title}:</div>
            <div>{data.isError ? <span className='text-light-negative dark:text-dark-negative'>Nedostupné</span> : <>{data.data}</>}</div>
        </li>
    );
};

const Logo: React.FC<{ publicUrl: string; alt: string; label?: string }> = ({ publicUrl, alt, label }) => {
    return (
        <div className='flex-1 flex flex-col items-center justify-center md:w-auto'>
            <img
                src={`${process.env.PUBLIC_URL}/${publicUrl}`}
                alt={alt}
                title={alt}
                className='max-w-60 max-h-24'
            />
            {!!label && <span className='text-base font-semibold'>{label}</span>}
        </div>
    );
};

const DashboardPage: React.FC = () => {
    const codelistInfoApi = useCodelistApi(CodelistInfoApi, {
        noErrorMiddleware: true
    });
    const catalogInfoApi = useCatalogApi(CatalogInfoApi, {
        noErrorMiddleware: true
    });
    const dataImportInfoApi = useDataImportApi(DataImportInfoApi, {
        noErrorMiddleware: true
    });
    const dataImportMetadataInfoApi = useDataImportMetadataApi(DataImportMetadataInfoApi, {
        noErrorMiddleware: true
    });
    const overviewInfoApi = useOverviewApi(OverviewInfoApi, {
        noErrorMiddleware: true
    });
    const notificatiorInfoApi = useNotificatorApi(NotificatiorInfoApi, {
        noErrorMiddleware: true
    });
    const userProfileInfoApi = useUserProfileApi(UserProfileInfoApi, {
        noErrorMiddleware: true
    });
    const catalogImportInfoApi = useCatalogImportApi(CatalogImportInfoApi, {
        noErrorMiddleware: true
    });
    const methodicImportInfoApi = useMethodicImportApi(MethodicImportInfoApi, {
        noErrorMiddleware: true
    });
    const supportingRegistersInfoApi = useSupportingRegistersApi(SupportingRegistersApi, {
        noErrorMiddleware: true
    });

    const codelistVersion = useQuery({
        queryKey: ['codelist', 'version'],
        queryFn: ({ signal }) => codelistInfoApi.version({ signal })
    });

    const catalogVersion = useQuery({
        queryKey: ['catalog', 'version'],
        queryFn: ({ signal }) => catalogInfoApi.version({ signal })
    });

    const dataImportVersion = useQuery({
        queryKey: ['data-import', 'version'],
        queryFn: ({ signal }) => dataImportInfoApi.version({ signal })
    });

    const dataImportMetadataVersion = useQuery({
        queryKey: ['data-import-metadata', 'version'],
        queryFn: ({ signal }) => dataImportMetadataInfoApi.version({ signal })
    });

    const overviewVersion = useQuery({
        queryKey: ['overview', 'version'],
        queryFn: ({ signal }) => overviewInfoApi.version({ signal })
    });

    const notificatorVersion = useQuery({
        queryKey: ['notificator', 'version'],
        queryFn: ({ signal }) => notificatiorInfoApi.version({ signal })
    });

    const userProfileVersion = useQuery({
        queryKey: ['user-profile', 'version'],
        queryFn: ({ signal }) => userProfileInfoApi.version({ signal })
    });

    const catalogImportVersion = useQuery({
        queryKey: ['catalog-import', 'version'],
        queryFn: ({ signal }) => catalogImportInfoApi.version({ signal })
    });

    const methodicImportVersion = useQuery({
        queryKey: ['methodic-import', 'version'],
        queryFn: ({ signal }) => methodicImportInfoApi.version({ signal })
    });

    const supportingRegistersVersion = useQuery({
        queryKey: ['supporting-registers', 'version'],
        queryFn: ({ signal }) => supportingRegistersInfoApi.version({ signal })
    });

    return (
        <>
            <Header breadcrumbs={[{ name: 'Domovská obrazovka', to: '/dashboard' }]} />
            <div className='text-light-text dark:text-dark-text'>
                <h1 className='my-10 text-center text-3xl font-medium'>Vitajte v Sobek UI</h1>
                <div className='flex xl:flex-row flex-col gap-x-4 gap-y-8 items-center place-items-center px-8 pb-8'>
                    <Logo
                        alt='Európska únia - Kohézny fond'
                        publicUrl='images/eu.svg'
                        label='Kohézny fond'
                    />
                    <Logo
                        alt='Operačný program - Kvalita životného prostredia'
                        publicUrl='images/opkzp.svg'
                    />
                    <Logo
                        alt='Ministerstvo životného prostredia Slovenskej republiky'
                        publicUrl='images/mzpsr.svg'
                    />
                    <Logo
                        alt='Slovenský hydrometeorologický ústav'
                        publicUrl='images/shmu.jpg'
                    />
                </div>
            </div>
            <div className='font-mono p-2 relative xl:fixed xl:right-0 xl:bottom-0 text-light-textLight dark:text-dark-textLight text-xs backdrop-blur-sm rounded-ss-md bg-light-backgroundLight dark:bg-dark-backgroundLight bg-opacity-60'>
                <div className='w-fit mx-auto'>
                    <h2 className='text-lg font-semibold'>Verzie modulov</h2>
                    <ul aria-label='Verzie modulov aplikácie'>
                        <li className='flex'>
                            <div className='min-w-64'>Používateľské prostredie:</div>
                            <div>{ENV.APP_VERSION}</div>
                        </li>
                        <ModulItem
                            title='Modul číselníkov'
                            data={codelistVersion}
                        />
                        <ModulItem
                            title='Modul katalógov'
                            data={catalogVersion}
                        />
                        <ModulItem
                            title='Modul importov analytických metód'
                            data={methodicImportVersion}
                        />
                        <ModulItem
                            title='Modul importov katalógov'
                            data={catalogImportVersion}
                        />
                        <ModulItem
                            title='Modul importov dát'
                            data={dataImportVersion}
                        />
                        <ModulItem
                            title='Modul metadát importov dát'
                            data={dataImportMetadataVersion}
                        />
                        <ModulItem
                            title='Modul prehľadov'
                            data={overviewVersion}
                        />
                        <ModulItem
                            title='Modul notifikácií'
                            data={notificatorVersion}
                        />
                        <ModulItem
                            title='Modul profilu používateľa'
                            data={userProfileVersion}
                        />
                        <ModulItem
                            title='Modul podporných registrov'
                            data={supportingRegistersVersion}
                        />
                        <li className='flex py-4'>
                            <Logo
                                alt='MICROCOMP - Computersystém s r.o.'
                                publicUrl='images/mc.svg'
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default DashboardPage;
