/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeItemAttributeIdentificationResponse } from './CodeItemAttributeIdentificationResponse';
import {
    CodeItemAttributeIdentificationResponseFromJSON,
    CodeItemAttributeIdentificationResponseFromJSONTyped,
    CodeItemAttributeIdentificationResponseToJSON,
} from './CodeItemAttributeIdentificationResponse';
import type { CodelistSearchDefinitionItemResponse } from './CodelistSearchDefinitionItemResponse';
import {
    CodelistSearchDefinitionItemResponseFromJSON,
    CodelistSearchDefinitionItemResponseFromJSONTyped,
    CodelistSearchDefinitionItemResponseToJSON,
} from './CodelistSearchDefinitionItemResponse';
import type { ControlDefinitionItemResponse } from './ControlDefinitionItemResponse';
import {
    ControlDefinitionItemResponseFromJSON,
    ControlDefinitionItemResponseFromJSONTyped,
    ControlDefinitionItemResponseToJSON,
} from './ControlDefinitionItemResponse';
import type { DataRegistryAttributeIdentificationResponse } from './DataRegistryAttributeIdentificationResponse';
import {
    DataRegistryAttributeIdentificationResponseFromJSON,
    DataRegistryAttributeIdentificationResponseFromJSONTyped,
    DataRegistryAttributeIdentificationResponseToJSON,
} from './DataRegistryAttributeIdentificationResponse';
import type { DataRegistryJoinDefinitionResponse } from './DataRegistryJoinDefinitionResponse';
import {
    DataRegistryJoinDefinitionResponseFromJSON,
    DataRegistryJoinDefinitionResponseFromJSONTyped,
    DataRegistryJoinDefinitionResponseToJSON,
} from './DataRegistryJoinDefinitionResponse';
import type { DataRegistrySearchDefinitionItemResponse } from './DataRegistrySearchDefinitionItemResponse';
import {
    DataRegistrySearchDefinitionItemResponseFromJSON,
    DataRegistrySearchDefinitionItemResponseFromJSONTyped,
    DataRegistrySearchDefinitionItemResponseToJSON,
} from './DataRegistrySearchDefinitionItemResponse';
import type { MonitoringPointAttributeIdentificationResponse } from './MonitoringPointAttributeIdentificationResponse';
import {
    MonitoringPointAttributeIdentificationResponseFromJSON,
    MonitoringPointAttributeIdentificationResponseFromJSONTyped,
    MonitoringPointAttributeIdentificationResponseToJSON,
} from './MonitoringPointAttributeIdentificationResponse';
import type { MonitoringPointSearchDefinitionItemResponse } from './MonitoringPointSearchDefinitionItemResponse';
import {
    MonitoringPointSearchDefinitionItemResponseFromJSON,
    MonitoringPointSearchDefinitionItemResponseFromJSONTyped,
    MonitoringPointSearchDefinitionItemResponseToJSON,
} from './MonitoringPointSearchDefinitionItemResponse';

/**
 * 
 * @export
 * @interface ImportFilePartControlResponse
 */
export interface ImportFilePartControlResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartControlResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartControlResponse
     */
    controlType?: ImportFilePartControlResponseControlTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartControlResponse
     */
    controlCriticalityType?: ImportFilePartControlResponseControlCriticalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartControlResponse
     */
    controlDataType?: ImportFilePartControlResponseControlDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartControlResponse
     */
    referenceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportFilePartControlResponse
     */
    controlOccurrenceReferenceAttributeType?: ImportFilePartControlResponseControlOccurrenceReferenceAttributeTypeEnum;
    /**
     * 
     * @type {Array<ControlDefinitionItemResponse>}
     * @memberof ImportFilePartControlResponse
     */
    controlDefinitionItems?: Array<ControlDefinitionItemResponse>;
    /**
     * 
     * @type {Array<DataRegistrySearchDefinitionItemResponse>}
     * @memberof ImportFilePartControlResponse
     */
    dataRegistrySearchDefinitionItems?: Array<DataRegistrySearchDefinitionItemResponse>;
    /**
     * 
     * @type {Array<DataRegistryJoinDefinitionResponse>}
     * @memberof ImportFilePartControlResponse
     */
    dataRegistryJoinDefinitions?: Array<DataRegistryJoinDefinitionResponse>;
    /**
     * 
     * @type {Array<DataRegistryAttributeIdentificationResponse>}
     * @memberof ImportFilePartControlResponse
     */
    dataRegistryAttributeIdentifications?: Array<DataRegistryAttributeIdentificationResponse>;
    /**
     * 
     * @type {Array<CodelistSearchDefinitionItemResponse>}
     * @memberof ImportFilePartControlResponse
     */
    codelistSearchDefinitionItems?: Array<CodelistSearchDefinitionItemResponse>;
    /**
     * 
     * @type {Array<CodeItemAttributeIdentificationResponse>}
     * @memberof ImportFilePartControlResponse
     */
    codeItemAttributeIdentifications?: Array<CodeItemAttributeIdentificationResponse>;
    /**
     * 
     * @type {Array<MonitoringPointSearchDefinitionItemResponse>}
     * @memberof ImportFilePartControlResponse
     */
    monitoringPointSearchDefinitionItems?: Array<MonitoringPointSearchDefinitionItemResponse>;
    /**
     * 
     * @type {Array<MonitoringPointAttributeIdentificationResponse>}
     * @memberof ImportFilePartControlResponse
     */
    monitoringPointAttributeIdentifications?: Array<MonitoringPointAttributeIdentificationResponse>;
}


/**
 * @export
 */
export const ImportFilePartControlResponseControlTypeEnum = {
    FileStructureControl: 'FILE_STRUCTURE_CONTROL',
    FileExtensionControl: 'FILE_EXTENSION_CONTROL',
    FileNameControl: 'FILE_NAME_CONTROL',
    HeaderControl: 'HEADER_CONTROL',
    LaboratoryControl: 'LABORATORY_CONTROL',
    ComparisonOfDeterminants: 'COMPARISON_OF_DETERMINANTS',
    AmountOfWaterTakenFilledEveryMonth: 'AMOUNT_OF_WATER_TAKEN_FILLED_EVERY_MONTH',
    ValueOfDeterminantInVNotHigherInP: 'VALUE_OF_DETERMINANT_IN_V_NOT_HIGHER_IN_P',
    AmountOfWaterDischargedFilledEveryMonth: 'AMOUNT_OF_WATER_DISCHARGED_FILLED_EVERY_MONTH',
    CodelistDeterminantControl: 'CODELIST_DETERMINANT_CONTROL',
    DuplicityNecSampleTypeDate: 'DUPLICITY_NEC_SAMPLE_TYPE_DATE',
    DuplicityNecDeterminantPV: 'DUPLICITY_NEC_DETERMINANT_P_V',
    ComplianceOfSampleDatesWithYear: 'COMPLIANCE_OF_SAMPLE_DATES_WITH_YEAR',
    DuplicitySampleTypeDate: 'DUPLICITY_SAMPLE_TYPE_DATE',
    TerrainAndLaboratoryDateMismatch: 'TERRAIN_AND_LABORATORY_DATE_MISMATCH',
    WeeklyWaterTemperatureWithinTimeSeries: 'WEEKLY_WATER_TEMPERATURE_WITHIN_TIME_SERIES',
    WeeklyWaterLevelWithinTimeSeries: 'WEEKLY_WATER_LEVEL_WITHIN_TIME_SERIES',
    HourlyWaterLevelWithinTimeSeries: 'HOURLY_WATER_LEVEL_WITHIN_TIME_SERIES',
    HourlyWaterTemperatureWithinTimeSeries: 'HOURLY_WATER_TEMPERATURE_WITHIN_TIME_SERIES',
    ValueExceededExtreme: 'VALUE_EXCEEDED_EXTREME',
    WaterLevelEmptyTemperatureEmpty: 'WATER_LEVEL_EMPTY_TEMPERATURE_EMPTY',
    YieldOfSpringEmptyTemperatureEmpty: 'YIELD_OF_SPRING_EMPTY_TEMPERATURE_EMPTY',
    YieldOfSpringRange: 'YIELD_OF_SPRING_RANGE',
    StateOfWaterInProbeRange: 'STATE_OF_WATER_IN_PROBE_RANGE',
    WaterTemperatureRangeUndergroundWater: 'WATER_TEMPERATURE_RANGE_UNDERGROUND_WATER',
    NonExistenceMonPointCode: 'NON_EXISTENCE_MON_POINT_CODE',
    NonExistenceBalanceCode: 'NON_EXISTENCE_BALANCE_CODE',
    BalanceCodeBlockedByDate: 'BALANCE_CODE_BLOCKED_BY_DATE',
    NoMonitoringActivityOnMonPoint: 'NO_MONITORING_ACTIVITY_ON_MON_POINT',
    LoqAgainstDevDetectLimit: 'LOQ_AGAINST_DEV_DETECT_LIMIT',
    LoqAgainstChvoDeterminantLimit: 'LOQ_AGAINST_CHVO_DETERMINANT_LIMIT',
    LoqAgainstAnMethodic: 'LOQ_AGAINST_AN_METHODIC',
    TnmnAnalysisMissingMandatoryData: 'TNMN_ANALYSIS_MISSING_MANDATORY_DATA',
    ExistenceSampleWhenUploadingAnalyses: 'EXISTENCE_SAMPLE_WHEN_UPLOADING_ANALYSES',
    OrganicComponentRange: 'ORGANIC_COMPONENT_RANGE',
    TurbidityRange: 'TURBIDITY_RANGE',
    FlowRange: 'FLOW_RANGE',
    WaterLevelRange: 'WATER_LEVEL_RANGE',
    DrySeason: 'DRY_SEASON',
    WaterTemperatureRangeSurfaceWater: 'WATER_TEMPERATURE_RANGE_SURFACE_WATER',
    NonCriticalWaterTemperatureRangeSurfaceWater: 'NON_CRITICAL_WATER_TEMPERATURE_RANGE_SURFACE_WATER',
    NonCriticalWaterTemperatureRangeUndergroundWater: 'NON_CRITICAL_WATER_TEMPERATURE_RANGE_UNDERGROUND_WATER',
    NonCriticalYieldOfSpringRange: 'NON_CRITICAL_YIELD_OF_SPRING_RANGE',
    StateOfWaterInProbeDepthOfWellAndHighOfMeasuringPoint: 'STATE_OF_WATER_IN_PROBE_DEPTH_OF_WELL_AND_HIGH_OF_MEASURING_POINT',
    NonCriticalStateOfWaterInProbeRange: 'NON_CRITICAL_STATE_OF_WATER_IN_PROBE_RANGE',
    CationAnionTest: 'CATION_ANION_TEST',
    SigmaTest: 'SIGMA_TEST',
    ExistenceDateOfDataInTimePeriodOfMountPointVersion: 'EXISTENCE_DATE_OF_DATA_IN_TIME_PERIOD_OF_MOUNT_POINT_VERSION',
    TotalAndDissolvedMetals: 'TOTAL_AND_DISSOLVED_METALS',
    BskChsk: 'BSK_CHSK',
    NitrogenBalance: 'NITROGEN_BALANCE',
    ComplianceWithGenerallyApplicableChemicalRules: 'COMPLIANCE_WITH_GENERALLY_APPLICABLE_CHEMICAL_RULES',
    ZeroAnalysisValues: 'ZERO_ANALYSIS_VALUES',
    DateFormat: 'DATE_FORMAT',
    MountPointNoVersion: 'MOUNT_POINT_NO_VERSION',
    CorrectnessOfUnit: 'CORRECTNESS_OF_UNIT',
    ExtremeData: 'EXTREME_DATA',
    NumericalDataOutsideAllowedRange: 'NUMERICAL_DATA_OUTSIDE_ALLOWED_RANGE',
    InvalidDateRange: 'INVALID_DATE_RANGE',
    UnknownCodeitemCode: 'UNKNOWN_CODEITEM_CODE',
    UnknownMountPointCode: 'UNKNOWN_MOUNT_POINT_CODE',
    DecimalSeparatorInterpretation: 'DECIMAL_SEPARATOR_INTERPRETATION',
    ImportFileDataRegistryDuplicity: 'IMPORT_FILE_DATA_REGISTRY_DUPLICITY',
    IncompleteTimeSeries: 'INCOMPLETE_TIME_SERIES',
    WarningImportFileRowDuplicity: 'WARNING_IMPORT_FILE_ROW_DUPLICITY',
    ImportFileRowDuplicityKeyAttributes: 'IMPORT_FILE_ROW_DUPLICITY_KEY_ATTRIBUTES',
    IncorrectDataType: 'INCORRECT_DATA_TYPE',
    ValueExceededExtremeQuantityUndergroundWater: 'VALUE_EXCEEDED_EXTREME_QUANTITY_UNDERGROUND_WATER',
    ValueExceededExtremeQuantitySurfaceWater: 'VALUE_EXCEEDED_EXTREME_QUANTITY_SURFACE_WATER',
    WaterLevelEmptyTemperatureEmptyDr: 'WATER_LEVEL_EMPTY_TEMPERATURE_EMPTY_DR',
    YieldOfSpringEmptyTemperatureEmptyDr: 'YIELD_OF_SPRING_EMPTY_TEMPERATURE_EMPTY_DR',
    DeterminantInDeterminantGroup: 'DETERMINANT_IN_DETERMINANT_GROUP',
    ValidityOfNotificationWithNotificationOfCommencement: 'VALIDITY_OF_NOTIFICATION_WITH_NOTIFICATION_OF_COMMENCEMENT',
    DeterminantIsNotZero: 'DETERMINANT_IS_NOT_ZERO',
    DeterminantIsNotZeroImport: 'DETERMINANT_IS_NOT_ZERO_IMPORT',
    ImportFileDataRegistryDuplicityTransposed: 'IMPORT_FILE_DATA_REGISTRY_DUPLICITY_TRANSPOSED'
} as const;
export type ImportFilePartControlResponseControlTypeEnum = typeof ImportFilePartControlResponseControlTypeEnum[keyof typeof ImportFilePartControlResponseControlTypeEnum];

/**
 * @export
 */
export const ImportFilePartControlResponseControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type ImportFilePartControlResponseControlCriticalityTypeEnum = typeof ImportFilePartControlResponseControlCriticalityTypeEnum[keyof typeof ImportFilePartControlResponseControlCriticalityTypeEnum];

/**
 * @export
 */
export const ImportFilePartControlResponseControlDataTypeEnum = {
    DataRegistry: 'DATA_REGISTRY',
    Codelist: 'CODELIST',
    MonPoint: 'MON_POINT',
    None: 'NONE',
    TimeSeries: 'TIME_SERIES'
} as const;
export type ImportFilePartControlResponseControlDataTypeEnum = typeof ImportFilePartControlResponseControlDataTypeEnum[keyof typeof ImportFilePartControlResponseControlDataTypeEnum];

/**
 * @export
 */
export const ImportFilePartControlResponseControlOccurrenceReferenceAttributeTypeEnum = {
    OneInMainRow: 'ONE_IN_MAIN_ROW',
    OneInEachDependentRow: 'ONE_IN_EACH_DEPENDENT_ROW',
    MoreInMainRow: 'MORE_IN_MAIN_ROW',
    OneInRow: 'ONE_IN_ROW',
    None: 'NONE'
} as const;
export type ImportFilePartControlResponseControlOccurrenceReferenceAttributeTypeEnum = typeof ImportFilePartControlResponseControlOccurrenceReferenceAttributeTypeEnum[keyof typeof ImportFilePartControlResponseControlOccurrenceReferenceAttributeTypeEnum];


/**
 * Check if a given object implements the ImportFilePartControlResponse interface.
 */
export function instanceOfImportFilePartControlResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportFilePartControlResponseFromJSON(json: any): ImportFilePartControlResponse {
    return ImportFilePartControlResponseFromJSONTyped(json, false);
}

export function ImportFilePartControlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportFilePartControlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'controlType': !exists(json, 'controlType') ? undefined : json['controlType'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'controlDataType': !exists(json, 'controlDataType') ? undefined : json['controlDataType'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'controlOccurrenceReferenceAttributeType': !exists(json, 'controlOccurrenceReferenceAttributeType') ? undefined : json['controlOccurrenceReferenceAttributeType'],
        'controlDefinitionItems': !exists(json, 'controlDefinitionItems') ? undefined : ((json['controlDefinitionItems'] as Array<any>).map(ControlDefinitionItemResponseFromJSON)),
        'dataRegistrySearchDefinitionItems': !exists(json, 'dataRegistrySearchDefinitionItems') ? undefined : ((json['dataRegistrySearchDefinitionItems'] as Array<any>).map(DataRegistrySearchDefinitionItemResponseFromJSON)),
        'dataRegistryJoinDefinitions': !exists(json, 'dataRegistryJoinDefinitions') ? undefined : ((json['dataRegistryJoinDefinitions'] as Array<any>).map(DataRegistryJoinDefinitionResponseFromJSON)),
        'dataRegistryAttributeIdentifications': !exists(json, 'dataRegistryAttributeIdentifications') ? undefined : ((json['dataRegistryAttributeIdentifications'] as Array<any>).map(DataRegistryAttributeIdentificationResponseFromJSON)),
        'codelistSearchDefinitionItems': !exists(json, 'codelistSearchDefinitionItems') ? undefined : ((json['codelistSearchDefinitionItems'] as Array<any>).map(CodelistSearchDefinitionItemResponseFromJSON)),
        'codeItemAttributeIdentifications': !exists(json, 'codeItemAttributeIdentifications') ? undefined : ((json['codeItemAttributeIdentifications'] as Array<any>).map(CodeItemAttributeIdentificationResponseFromJSON)),
        'monitoringPointSearchDefinitionItems': !exists(json, 'monitoringPointSearchDefinitionItems') ? undefined : ((json['monitoringPointSearchDefinitionItems'] as Array<any>).map(MonitoringPointSearchDefinitionItemResponseFromJSON)),
        'monitoringPointAttributeIdentifications': !exists(json, 'monitoringPointAttributeIdentifications') ? undefined : ((json['monitoringPointAttributeIdentifications'] as Array<any>).map(MonitoringPointAttributeIdentificationResponseFromJSON)),
    };
}

export function ImportFilePartControlResponseToJSON(value?: ImportFilePartControlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'controlType': value.controlType,
        'controlCriticalityType': value.controlCriticalityType,
        'controlDataType': value.controlDataType,
        'referenceCode': value.referenceCode,
        'controlOccurrenceReferenceAttributeType': value.controlOccurrenceReferenceAttributeType,
        'controlDefinitionItems': value.controlDefinitionItems === undefined ? undefined : ((value.controlDefinitionItems as Array<any>).map(ControlDefinitionItemResponseToJSON)),
        'dataRegistrySearchDefinitionItems': value.dataRegistrySearchDefinitionItems === undefined ? undefined : ((value.dataRegistrySearchDefinitionItems as Array<any>).map(DataRegistrySearchDefinitionItemResponseToJSON)),
        'dataRegistryJoinDefinitions': value.dataRegistryJoinDefinitions === undefined ? undefined : ((value.dataRegistryJoinDefinitions as Array<any>).map(DataRegistryJoinDefinitionResponseToJSON)),
        'dataRegistryAttributeIdentifications': value.dataRegistryAttributeIdentifications === undefined ? undefined : ((value.dataRegistryAttributeIdentifications as Array<any>).map(DataRegistryAttributeIdentificationResponseToJSON)),
        'codelistSearchDefinitionItems': value.codelistSearchDefinitionItems === undefined ? undefined : ((value.codelistSearchDefinitionItems as Array<any>).map(CodelistSearchDefinitionItemResponseToJSON)),
        'codeItemAttributeIdentifications': value.codeItemAttributeIdentifications === undefined ? undefined : ((value.codeItemAttributeIdentifications as Array<any>).map(CodeItemAttributeIdentificationResponseToJSON)),
        'monitoringPointSearchDefinitionItems': value.monitoringPointSearchDefinitionItems === undefined ? undefined : ((value.monitoringPointSearchDefinitionItems as Array<any>).map(MonitoringPointSearchDefinitionItemResponseToJSON)),
        'monitoringPointAttributeIdentifications': value.monitoringPointAttributeIdentifications === undefined ? undefined : ((value.monitoringPointAttributeIdentifications as Array<any>).map(MonitoringPointAttributeIdentificationResponseToJSON)),
    };
}

