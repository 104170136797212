/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageableObject } from './PageableObject';
import {
    PageableObjectFromJSON,
    PageableObjectFromJSONTyped,
    PageableObjectToJSON,
} from './PageableObject';
import type { SortObject } from './SortObject';
import {
    SortObjectFromJSON,
    SortObjectFromJSONTyped,
    SortObjectToJSON,
} from './SortObject';
import type { SubmissionRecord } from './SubmissionRecord';
import {
    SubmissionRecordFromJSON,
    SubmissionRecordFromJSONTyped,
    SubmissionRecordToJSON,
} from './SubmissionRecord';

/**
 * 
 * @export
 * @interface PageSubmissionRecord
 */
export interface PageSubmissionRecord {
    /**
     * 
     * @type {number}
     * @memberof PageSubmissionRecord
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSubmissionRecord
     */
    totalElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageSubmissionRecord
     */
    last?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSubmissionRecord
     */
    first?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSubmissionRecord
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageSubmissionRecord
     */
    size?: number;
    /**
     * 
     * @type {Array<SubmissionRecord>}
     * @memberof PageSubmissionRecord
     */
    content?: Array<SubmissionRecord>;
    /**
     * 
     * @type {number}
     * @memberof PageSubmissionRecord
     */
    number?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageSubmissionRecord
     */
    sort?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageSubmissionRecord
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageSubmissionRecord
     */
    empty?: boolean;
}

/**
 * Check if a given object implements the PageSubmissionRecord interface.
 */
export function instanceOfPageSubmissionRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageSubmissionRecordFromJSON(json: any): PageSubmissionRecord {
    return PageSubmissionRecordFromJSONTyped(json, false);
}

export function PageSubmissionRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSubmissionRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'first': !exists(json, 'first') ? undefined : json['first'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableObjectFromJSON(json['pageable']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(SubmissionRecordFromJSON)),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'sort': !exists(json, 'sort') ? undefined : SortObjectFromJSON(json['sort']),
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
    };
}

export function PageSubmissionRecordToJSON(value?: PageSubmissionRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'last': value.last,
        'first': value.first,
        'pageable': PageableObjectToJSON(value.pageable),
        'size': value.size,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(SubmissionRecordToJSON)),
        'number': value.number,
        'sort': SortObjectToJSON(value.sort),
        'numberOfElements': value.numberOfElements,
        'empty': value.empty,
    };
}

