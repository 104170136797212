/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RowAttributeDefinitionWithColumnPositionResponse
 */
export interface RowAttributeDefinitionWithColumnPositionResponse {
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionWithColumnPositionResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionWithColumnPositionResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RowAttributeDefinitionWithColumnPositionResponse
     */
    rowAttributeType?: RowAttributeDefinitionWithColumnPositionResponseRowAttributeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof RowAttributeDefinitionWithColumnPositionResponse
     */
    columnPosition?: number;
    /**
     * 
     * @type {number}
     * @memberof RowAttributeDefinitionWithColumnPositionResponse
     */
    columnStartPosition?: number;
}


/**
 * @export
 */
export const RowAttributeDefinitionWithColumnPositionResponseRowAttributeTypeEnum = {
    Primary: 'PRIMARY',
    Reference: 'REFERENCE',
    Auxiliary: 'AUXILIARY',
    HeaderName: 'HEADER_NAME',
    HeaderUnit: 'HEADER_UNIT'
} as const;
export type RowAttributeDefinitionWithColumnPositionResponseRowAttributeTypeEnum = typeof RowAttributeDefinitionWithColumnPositionResponseRowAttributeTypeEnum[keyof typeof RowAttributeDefinitionWithColumnPositionResponseRowAttributeTypeEnum];


/**
 * Check if a given object implements the RowAttributeDefinitionWithColumnPositionResponse interface.
 */
export function instanceOfRowAttributeDefinitionWithColumnPositionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RowAttributeDefinitionWithColumnPositionResponseFromJSON(json: any): RowAttributeDefinitionWithColumnPositionResponse {
    return RowAttributeDefinitionWithColumnPositionResponseFromJSONTyped(json, false);
}

export function RowAttributeDefinitionWithColumnPositionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RowAttributeDefinitionWithColumnPositionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'rowAttributeType': !exists(json, 'rowAttributeType') ? undefined : json['rowAttributeType'],
        'columnPosition': !exists(json, 'columnPosition') ? undefined : json['columnPosition'],
        'columnStartPosition': !exists(json, 'columnStartPosition') ? undefined : json['columnStartPosition'],
    };
}

export function RowAttributeDefinitionWithColumnPositionResponseToJSON(value?: RowAttributeDefinitionWithColumnPositionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'rowAttributeType': value.rowAttributeType,
        'columnPosition': value.columnPosition,
        'columnStartPosition': value.columnStartPosition,
    };
}

