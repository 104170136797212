/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidationResultResponse } from './ValidationResultResponse';
import {
    ValidationResultResponseFromJSON,
    ValidationResultResponseFromJSONTyped,
    ValidationResultResponseToJSON,
} from './ValidationResultResponse';

/**
 * 
 * @export
 * @interface DataAttributeResponse
 */
export interface DataAttributeResponse {
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    value?: string;
    /**
     * 
     * @type {number}
     * @memberof DataAttributeResponse
     */
    numericValue?: number;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    dateValue?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    dateTimeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    dataType?: DataAttributeResponseDataTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DataAttributeResponse
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    rowAttributeType?: DataAttributeResponseRowAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    status?: DataAttributeResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DataAttributeResponse
     */
    positionRow?: number;
    /**
     * 
     * @type {number}
     * @memberof DataAttributeResponse
     */
    positionColumn?: number;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    modifier?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    modified?: string;
    /**
     * 
     * @type {string}
     * @memberof DataAttributeResponse
     */
    documentId?: string;
    /**
     * 
     * @type {Array<ValidationResultResponse>}
     * @memberof DataAttributeResponse
     */
    validationResult?: Array<ValidationResultResponse>;
}


/**
 * @export
 */
export const DataAttributeResponseDataTypeEnum = {
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Null: 'NULL',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    IsoDateTime: 'ISO_DATE_TIME',
    Bfile: 'BFILE',
    Bit: 'BIT',
    Binary: 'BINARY',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type DataAttributeResponseDataTypeEnum = typeof DataAttributeResponseDataTypeEnum[keyof typeof DataAttributeResponseDataTypeEnum];

/**
 * @export
 */
export const DataAttributeResponseRowAttributeTypeEnum = {
    Primary: 'PRIMARY',
    Reference: 'REFERENCE',
    Auxiliary: 'AUXILIARY',
    Calculated: 'CALCULATED',
    HeaderName: 'HEADER_NAME',
    HeaderUnit: 'HEADER_UNIT'
} as const;
export type DataAttributeResponseRowAttributeTypeEnum = typeof DataAttributeResponseRowAttributeTypeEnum[keyof typeof DataAttributeResponseRowAttributeTypeEnum];

/**
 * @export
 */
export const DataAttributeResponseStatusEnum = {
    New: 'NEW',
    Changed: 'CHANGED',
    Validated: 'VALIDATED',
    Unsaved: 'UNSAVED',
    SavedInTheSourceDb: 'SAVED_IN_THE_SOURCE_DB',
    Calculated: 'CALCULATED'
} as const;
export type DataAttributeResponseStatusEnum = typeof DataAttributeResponseStatusEnum[keyof typeof DataAttributeResponseStatusEnum];


/**
 * Check if a given object implements the DataAttributeResponse interface.
 */
export function instanceOfDataAttributeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataAttributeResponseFromJSON(json: any): DataAttributeResponse {
    return DataAttributeResponseFromJSONTyped(json, false);
}

export function DataAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataAttributeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'numericValue': !exists(json, 'numericValue') ? undefined : json['numericValue'],
        'dateValue': !exists(json, 'dateValue') ? undefined : json['dateValue'],
        'dateTimeValue': !exists(json, 'dateTimeValue') ? undefined : json['dateTimeValue'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'rowAttributeType': !exists(json, 'rowAttributeType') ? undefined : json['rowAttributeType'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'positionRow': !exists(json, 'positionRow') ? undefined : json['positionRow'],
        'positionColumn': !exists(json, 'positionColumn') ? undefined : json['positionColumn'],
        'modifier': !exists(json, 'modifier') ? undefined : json['modifier'],
        'modified': !exists(json, 'modified') ? undefined : json['modified'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'validationResult': !exists(json, 'validationResult') ? undefined : ((json['validationResult'] as Array<any>).map(ValidationResultResponseFromJSON)),
    };
}

export function DataAttributeResponseToJSON(value?: DataAttributeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'value': value.value,
        'numericValue': value.numericValue,
        'dateValue': value.dateValue,
        'dateTimeValue': value.dateTimeValue,
        'dataType': value.dataType,
        'required': value.required,
        'rowAttributeType': value.rowAttributeType,
        'note': value.note,
        'status': value.status,
        'positionRow': value.positionRow,
        'positionColumn': value.positionColumn,
        'modifier': value.modifier,
        'modified': value.modified,
        'documentId': value.documentId,
        'validationResult': value.validationResult === undefined ? undefined : ((value.validationResult as Array<any>).map(ValidationResultResponseToJSON)),
    };
}

