import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { isEqual } from 'lodash';

// TODO rozsirit o vsetky role !!! vystizne nazvy

export enum ROLE_OPERATION {
    READ = 'READ', // Prehľady
    WRITE = 'WRITE' // Importy a Prehľady
}

export enum ROLE_APPLICATION {
    OVERVIEW = 'overview',
    IMPORT = 'import'
}

export enum ROLE_DOMAIN {
    // Domenove role
    SK_SHMU_ODPADOVE_VODY_KVALITA = 'sk.shmu.odpadove-vody.kvalita',
    SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PV = 'sk.shmu.oznamovacia-povinnost.odbery-pv',
    SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PZV = 'sk.shmu.oznamovacia-povinnost.odbery-pzv',
    SK_SHMU_OZNAMOVACIA_POVINNOST_VYPUSTANIE_ODPADOVE_VODY = 'sk.shmu.oznamovacia-povinnost.vypustanie-odpadove-vody',
    SK_SHMU_PODZEMNE_VODY_KVALITA_PZV = 'sk.shmu.podzemne-vody.kvalita-pzv',
    SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV = 'sk.shmu.podzemne-vody.kvantita-pzv.kvantita-pzv',
    SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV = 'sk.shmu.podzemne-vody.kvantita-pzv.vhb-kvantita-pzv',
    SK_SHMU_POVRCHOVE_VODY_KVALITA_PV = 'sk.shmu.povrchove-vody.kvalita-pv',
    SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV = 'sk.shmu.povrchove-vody.kvantita-pv.kvantita-pv',
    SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_MUTNOST_VODY = 'sk.shmu.povrchove-vody.kvantita-pv.mutnost-vody',
    SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV = 'sk.shmu.povrchove-vody.kvantita-pv.vhb-kvantita-pv',
    SK_SHMU = 'sk.shmu'
}

export enum ROLES {
    ADMINISTRATOR = 'administrator', // moze vsetko

    CODELIST_READ = 'citatel-ciselnikov', // moze zobrazit ciselniky a ich polozky
    CODELIST_WRITE = 'spravca-ciselnikov', // moze vytvarat a editovat ciselniky a ich polozky

    ENVIRONMENT_READ = 'citatel-vodnych-tokov', // moze citat vodne toky
    ENVIRONMENT_WRITE = 'spravca-vodnych-tokov', // moze vytvarat a editovat vodne toky
    OPERATOR_AND_INSTALLATION_READ = 'citatel-prevadzkovatelov-a-prevadzok', // moze citat prevadzkovatelov a prevadzky
    OPERATOR_AND_INSTALLATION_WRITE = 'spravca-prevadzkovatelov-a-prevadzok', // moze vytvarat a editovat prevadzkovatelov a prevadzky
    WATER_SHAPE_READ = 'citatel-vodnych-utvarov', // moze citat vodne utvary
    WATER_SHAPE_WRITE = 'spravca-vodnych-utvarov', // moze vytvarat a editovat vodne utvary

    MON_POINT_QUALITY_PV_READ = 'citatel-miest-kvality-pv', // 'moze citat monitorovacie miesta kvality pv (27000001)'
    MON_POINT_QUALITY_PV_WRITE = 'spravca-miest-kvality-pv', // 'moze vytvarat a editovat monitorovacie miesta kvality pv (27000001)'
    MON_POINT_QUANTITY_PV_READ = 'citatel-miest-kvantity-pv', // 'moze citat monitorovacie miesta kvantity pv (27000008) a virtualnu stanicu (27000023)'
    MON_POINT_QUANTITY_PV_WRITE = 'spravca-miest-kvantity-pv', // 'moze vytvarat a editovat monitorovacie miesta kvantity pv (27000008) a virtualnu stanicu (27000023)'
    MON_POINT_QUANTITY_VHB_PV_READ = 'citatel-miest-kvantity-vhb-pv', // 'moze citat monitorovacie miesta kvantity vhb pv (27000009, 27000012, 27000013, 27000016)'
    MON_POINT_QUANTITY_VHB_PV_WRITE = 'spravca-miest-kvantity-vhb-pv', // 'moze vytvarat a editovat monitorovacie miesta kvantity vhb pv (27000009, 27000012, 27000013, 27000016)'
    MON_POINT_ABSTRACTION_PV_READ = 'citatel-miest-odbery-pv', // 'moze citat monitorovacie miesta odbery pv (27000002)'
    MON_POINT_ABSTRACTION_PV_WRITE = 'spravca-miest-odbery-pv', // 'moze vytvarat a editovat monitorovacie miesta odbery pv (27000002)'
    MON_POINT_DISCHARGE_PV_READ = 'citatel-miest-vypustanie-pv', // 'moze citat monitorovacie miesta vypustanie pv (27000003)'
    MON_POINT_DISCHARGE_PV_WRITE = 'spravca-miest-vypustanie-pv', // 'moze vytvarat a editovat monitorovacie miesta vypustanie pv (27000003)'
    MON_POINT_TNMN_PV_READ = 'citatel-miest-tnmn-pv', // 'moze citat monitorovacie miesta tnmn pv (27000011)'
    MON_POINT_TNMN_PV_WRITE = 'spravca-miest-tnmn-pv', // 'moze vytvarat a editovat monitorovacie miesta tnmn pv (27000011)'
    MON_POINT_HYMO_PV_READ = 'citatel-miest-hymo-pv', // 'moze citat monitorovacie miesta hymo pv (27000001, 27000008, 27000019) a virtualnu stanicu (27000023)'
    MON_POINT_HYMO_PV_WRITE = 'spravca-miest-hymo-pv', // 'moze vytvarat a editovat monitorovacie miesta hymo pv (27000001, 27000008, 27000019) a virtualnu stanicu (27000023)'
    MON_POINT_QUALITY_PZV_READ = 'citatel-miest-kvality-pzv', // 'moze citat monitorovacie miesta kvality pzv (27000021, 27000022)'
    MON_POINT_QUALITY_PZV_WRITE = 'spravca-miest-kvality-pzv', // 'moze vytvarat a editovat monitorovacie miesta kvality pzv (27000021, 27000022)'
    MON_POINT_QUANTITY_PZV_READ = 'citatel-miest-kvantity-pzv', // 'moze citat monitorovacie miesta kvantity pzv (27000005, 27000007)'
    MON_POINT_QUANTITY_PZV_WRITE = 'spravca-miest-kvantity-pzv', // 'moze vytvarat a editovat monitorovacie miesta kvantity pzv (27000005, 27000007)'
    MON_POINT_QUANTITY_VHB_PZV_READ = 'citatel-miest-kvantity-vhb-pzv', // 'moze citat monitorovacie miesta kvantity vhb-pzv (27000016, 27000018)'
    MON_POINT_QUANTITY_VHB_PZV_WRITE = 'spravca-miest-kvantity-vhb-pzv', // 'moze vytvarat a editovat monitorovacie miesta kvantity vhb pzv (27000016, 27000018)'
    MON_POINT_ABSTRACTON_PZV_READ = 'citatel-miest-odbery-pzv', // 'moze citat monitorovacie miesta odbery pzv (27000006, 27000017)'
    MON_POINT_ABSTRACTON_PZV_WRITE = 'spravca-miest-odbery-pzv', // 'moze vytvarat a editovat monitorovacie miesta odbery pzv (27000006, 27000017)'
    MON_POINT_DISCHARGE_PZV_READ = 'citatel-miest-vypustanie-pzv', // 'moze citat monitorovacie miesta vypustanie pzv (27000020)'
    MON_POINT_DISCHARGE_PZV_WRITE = 'spravca-miest-vypustanie-pzv', // 'moze vytvarat a editovat monitorovacie miesta vypustanie pzv (27000020)'
    MON_POINT_QUALITY_TESTIMONIALS_READ = 'citatel-miest-kvality-posudky', // 'moze citat monitorovacie miesta kvality posudky (27000014)'
    MON_POINT_QUALITY_TESTIMONIALS_WRITE = 'spravca-miest-kvality-posudky', // 'moze vytvarat a editovat monitorovacie miesta kvality posudky (27000014)'
    MON_POINT_QUANTITY_TESTIMONIALS_READ = 'citatel-miest-kvantity-posudky', // 'moze citat monitorovacie miesta kvantity posudky (27000015)'
    MON_POINT_QUANTITY_TESTIMONIALS_WRITE = 'spravca-miest-kvantity-posudky', // 'moze vytvarat a editovat monitorovacie miesta kvantity posudky (27000015)'
    MON_POINT_ALL_READ = 'citatel-miest', // 'citatel-miest (všetky)'
    MON_POINT_ALL_WRITE = 'spravca-miest', // 'spravca-miest (všetky)'

    ASSESSMENTS_READ = 'citatel-posudky', // Umožňuje prehliadať záznamy evidované v module Vytváranie posudkov
    ASSESSMENTS_WRITE = 'editor-posudky', // Umožňuje vytvárať a upravovať záznamy v module Vytváranie posudkov

    HYDRO_MORPHOLOGY_READ = 'citatel-hydromorfologia', // Umožňuje prehliadať protokoly zaevidované v module Hydromorfológia
    HYDRO_MORPHOLOGY_WRITE = 'editor-hydromorfologia', // Umožňuje vytvárať, editovať a mazať protokoly zaevidované v module Hydromorfológia

    MANDATORY_REPORTS_PDZV_READ = 'citatel-dosle-oznamenia-pdzv', // Umožňuje prelihadať záznamy v module Došlé oznámenia, ktoré sa týkajú PODZEMNÝCH vôd
    MANDATORY_REPORTS_PDZV_WRITE = 'editor-dosle-oznamenia-pdzv', // Umožňuje vytvárať a upravovať záznamy v module Došlé oznámenia, ktoré sa týkajú PODZEMNÝCH vôd
    MANDATORY_REPORTS_PV_READ = 'citatel-dosle-oznamenia-pv', // Umožňuje prelihadať záznamy v module Došlé oznámenia, ktoré sa týkajú POVRCHOVÝCH vôd
    MANDATORY_REPORTS_PV_WRITE = 'editor-dosle-oznamenia-pv', // Umožňuje vytvárať a upravovať záznamy v module Došlé oznámenia, ktoré sa týkajú POVRCHOVÝCH vôd

    WATER_LAW_RECORDS_READ = 'citatel-vodopravnej-evidencie', // Umožňuje prehliadať záznamy v module Vodoprávnej evidencie
    WATER_LAW_RECORDS_WRITE = 'editor-vodopravnej-evidencie', // Umožňuje vytvárať, editovať a mazať záznamy v module Vodoprávnej evidencie
    WATER_LAW_RECORDS_EXTERNAL_WRITE = 'editor-vodopravnej-evidencie-externy', // Umožňuje EXTERNÝM používateľom (okresné úrady) vytvárať a upravovať záznamy v module Vodoprávnej evidencie

    EXTERNAL_INFORMANT = 'oznamovatel-externy' // Externy oznamovatel, nema vidiet nic
}

const useRole = (): {
    hasSome: (roles: ROLES[] | ROLES) => boolean;
    hasEvery: (roles: ROLES[] | ROLES) => boolean;
    hasSomeDomainRole: (domain: ROLE_DOMAIN | ROLE_DOMAIN[], expectedApplication: ROLE_APPLICATION, operation?: ROLE_OPERATION) => boolean;
    hasSomeAppRole: (expectedApplication: ROLE_APPLICATION) => boolean;
} => {
    const { user } = useAuth();

    const [profileRoles, setProfileRoles] = useState<string[]>(() => {
        return (user?.profile.roles ?? []) as string[];
    });

    useEffect(() => {
        setProfileRoles((prev) => {
            if (isEqual(prev, user?.profile.roles)) {
                return prev;
            }
            return (user?.profile.roles ?? []) as ROLES[];
        });
    }, [user]);

    const hasSome = useCallback(
        (roles: ROLES[] | ROLES) => {
            if (!Array.isArray(roles)) {
                return profileRoles.includes(roles);
            }
            if (roles.length > 0 && !roles.some((r) => profileRoles.includes(r))) {
                return false;
            }
            return true;
        },
        [profileRoles]
    );

    const hasEvery = useCallback(
        (roles: ROLES[] | ROLES) => {
            if (!Array.isArray(roles)) {
                return profileRoles.includes(roles);
            }
            if (roles.length > 0 && !roles.every((r) => profileRoles.includes(r))) {
                return false;
            }
            return true;
        },
        [profileRoles]
    );

    const hasSomeDomainRole = useCallback(
        (expectedDomain: ROLE_DOMAIN | ROLE_DOMAIN[], expectedApplication: ROLE_APPLICATION, expectedOperation?: ROLE_OPERATION) => {
            const _expectedDomain = Array.isArray(expectedDomain) ? expectedDomain : [expectedDomain];
            return profileRoles
                .filter((r) => r.split(':').length === 3)
                .map(
                    (r) =>
                        r.split(':').map((rolePart) => rolePart.trim()) as [
                            domain: ROLE_DOMAIN,
                            application: ROLE_APPLICATION,
                            operation: ROLE_OPERATION
                        ]
                )
                .some(([domain, application, operation]) => {
                    if (expectedOperation) {
                        return _expectedDomain.includes(domain) && expectedApplication === application && expectedOperation === operation;
                    }
                    return _expectedDomain.includes(domain) && expectedApplication === application;
                });
        },
        [profileRoles]
    );

    const hasSomeAppRole = useCallback(
        (expectedApplication: ROLE_APPLICATION) => {
            return profileRoles.some((r) => r.split(':').length === 3 && r.split(':')[1] === expectedApplication);
        },
        [profileRoles]
    );

    const result = useMemo(() => {
        return {
            hasSome,
            hasEvery,
            hasSomeDomainRole,
            hasSomeAppRole
        };
    }, [hasSome, hasEvery, hasSomeDomainRole, hasSomeAppRole]);

    return result;
};

export default useRole;
