/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataRegistrySearchDefinitionItemRequest
 */
export interface DataRegistrySearchDefinitionItemRequest {
    /**
     * 
     * @type {string}
     * @memberof DataRegistrySearchDefinitionItemRequest
     */
    dataRegistryProductAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistrySearchDefinitionItemRequest
     */
    rowAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistrySearchDefinitionItemRequest
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistrySearchDefinitionItemRequest
     */
    valueDataType?: DataRegistrySearchDefinitionItemRequestValueDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataRegistrySearchDefinitionItemRequest
     */
    referenceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistrySearchDefinitionItemRequest
     */
    operatorType?: DataRegistrySearchDefinitionItemRequestOperatorTypeEnum;
}


/**
 * @export
 */
export const DataRegistrySearchDefinitionItemRequestValueDataTypeEnum = {
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Null: 'NULL',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    IsoDateTime: 'ISO_DATE_TIME',
    Bit: 'BIT',
    Binary: 'BINARY',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type DataRegistrySearchDefinitionItemRequestValueDataTypeEnum = typeof DataRegistrySearchDefinitionItemRequestValueDataTypeEnum[keyof typeof DataRegistrySearchDefinitionItemRequestValueDataTypeEnum];

/**
 * @export
 */
export const DataRegistrySearchDefinitionItemRequestOperatorTypeEnum = {
    Equals: 'EQUALS',
    NotEquals: 'NOT_EQUALS',
    LessThan: 'LESS_THAN',
    LessThanOrEquals: 'LESS_THAN_OR_EQUALS',
    GreaterThan: 'GREATER_THAN',
    GreaterThanOrEquals: 'GREATER_THAN_OR_EQUALS',
    Like: 'LIKE',
    NotNull: 'NOT_NULL'
} as const;
export type DataRegistrySearchDefinitionItemRequestOperatorTypeEnum = typeof DataRegistrySearchDefinitionItemRequestOperatorTypeEnum[keyof typeof DataRegistrySearchDefinitionItemRequestOperatorTypeEnum];


/**
 * Check if a given object implements the DataRegistrySearchDefinitionItemRequest interface.
 */
export function instanceOfDataRegistrySearchDefinitionItemRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataRegistrySearchDefinitionItemRequestFromJSON(json: any): DataRegistrySearchDefinitionItemRequest {
    return DataRegistrySearchDefinitionItemRequestFromJSONTyped(json, false);
}

export function DataRegistrySearchDefinitionItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataRegistrySearchDefinitionItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataRegistryProductAttributeCode': !exists(json, 'dataRegistryProductAttributeCode') ? undefined : json['dataRegistryProductAttributeCode'],
        'rowAttributeCode': !exists(json, 'rowAttributeCode') ? undefined : json['rowAttributeCode'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'valueDataType': !exists(json, 'valueDataType') ? undefined : json['valueDataType'],
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'operatorType': !exists(json, 'operatorType') ? undefined : json['operatorType'],
    };
}

export function DataRegistrySearchDefinitionItemRequestToJSON(value?: DataRegistrySearchDefinitionItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataRegistryProductAttributeCode': value.dataRegistryProductAttributeCode,
        'rowAttributeCode': value.rowAttributeCode,
        'value': value.value,
        'valueDataType': value.valueDataType,
        'referenceCode': value.referenceCode,
        'operatorType': value.operatorType,
    };
}

